<template>
    <v-card flat class="text-center d-flex justify-center align-center" style="height:100vh;">
      <v-row class="pa-0 ma-0">
        <v-col cols="11" sm="10" md="5" lg="4" class="ma-auto" style="z-index:1">
          <v-row class="ma-3">
            <v-col cols="12" class="pa-0 ma-0">
              <v-icon color="custom-accent lighten-1" size="100">mdi-emoticon-sad-outline</v-icon>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <div class="text-h2 secondary--text font-weight-medium mb-1">Oops!</div>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <span class="text-h5 secondary--text ">{{$t("errordesc")}}</span>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0 mt-7">
              <v-btn class="custom-accent lighten-1 text-center" :title="$t('home')" dark depressed @click="$navigateToPath('/')"><v-icon>mdi-home</v-icon><span class="ma-0 pa-0 mt-1">{{$t("home")}}</span></v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
</template>
<script>
export default {
  methods:{
  }
}
</script>